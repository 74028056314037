<template>
  <div class="topCreatorsPosition">
    <label class="positionInput" v-for="(pos, index) in num" :key="index">
      <IntrinsicSVG />
      <input type="radio" name="priority" :value="Number(index + 1)" :checked="Number(index + 1) === Number(value)" />
      <span class="figure"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data: function() {
    return {
      num: 30,
    };
  },
};
</script>

<style lang="scss">
.topCreatorsPosition {
  .positionInput {
    display: block;
    width: 100%;
    position: relative;
  }
  .figure {
    display: block;
    width: 100%;
    height: 100%;
    background-color: $alto;
    border-radius: $mpadding;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    &:hover {
      background-color: $primary_color_saturate;
    }
  }
  input {
    opacity: 0;
    position: absolute;
  }
  input:checked + .figure {
    background-color: $primary_color;
    color: $white;
  }
  .positionInput svg {
    max-width: 100%;
    height: auto;
  }
}
</style>
